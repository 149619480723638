import React, {memo, useContext, useEffect, useState} from 'react';
import theme from './style';
import {Grid, TableBody, TableCell, TableContainer, TableRow, Typography,} from '@material-ui/core';
import Button from '../../components/Button';
import {ListItem, SmallTitle, ViewTypeSelector, Categories, Text} from '../../components/DataDisplay';
import {Select} from '../../components/Inputs';
import translate from '../../functions/applyTranslation';
import clsx from 'clsx';
import {ActivitiesContext, Provider} from "./actitiesContext";
import {Fragment} from 'react';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import usePermissionsHook from '../../functions/permissions';
import useStatus from '../../settings/statusList';
import {useDispatch, useSelector} from 'react-redux';
import {setWideCard} from "../../redux/settings/settingsActions";
import ActivitiesListTable from "../../components/Table/ActivitiesListTable";

const ActivitiesList = (props) => {
  const [date, setDate] = useState(null);
  const dispatch = useDispatch();
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const localizer = momentLocalizer(moment);
  const classes = theme();
  const history = useHistory();
  const [statusList, statusFilter] = useStatus();
  const activitiesContext = useContext(ActivitiesContext);
  const auth = useSelector(state => state.auth);
  const {
    filters,
    categories,
    activities,
    view,
    setFilters,
    setView,
    handlePagination,
    sort,
    setSort,
    guides,
    fetchAllGuides,
    page,
    rowsPerPage,
    totalItems,
    setPage,
    setRowsPerPage,
    filter,
    setFilter,
    fetchActivities,
    checkBoxes,
    setCheckBoxes,
    noGuideTours,
    submitGuideChange,
    calRange,
    setCalRange,
    exportEvents
  } = activitiesContext;

  //let showTable = auth.userData.role === "admin" || auth.userData.role === "superadmin" || auth.userData.role === "security" || auth.userData.tour_guide === "1";
  const [showTable, setShowTable] = useState(auth.userData.tour_guide == "1");

  const sumArticlesTotal = () => {
    let total = 0;
    categories.forEach(item => {
      total += Number(item.num_of_articles)
    })
    return total;
  }
  const customeEventItem = date => {
    return {
      style: {
        backgroundColor: date.color,
        border: date.color,
      },
    }
  };

  const getColor = event => {
    let color = "#005DAA";  // Default blue color

    // Case for tour guides with status 2 (Assuming specific color needed)
    if (event.jfm_event.tour_guide && event.jfm_event.status === "2") {
      return "#FF9000";  // Assuming orange for tour guides with status 2, change as needed
    }

    // Conditions for event type and status
    if (event.event.type === "event") {
      if (event.jfm_event.event_status === "1") {
        color = "#008CFF";  // Light blue for event_status 1
      } else if (event.jfm_event.event_status === "2") {
        color = "#FF9000";  // Orange for event_status 2
      } else {
        color = "#FF9000";  // Orange for any other statuses for event types
      }
    } else if (event.event.type === "meeting" || event.event.type === "synagogue") {
      color = "#005DAA";  // Default blue for meetings and synagogue
      if (!event.jfm_event.tour_guide) {
        color = "#008CFF";  // Light blue for event_status 1
      }
    } else {
      color = "#005DAA";
    }

    return color;
  };

  const handleChangeView = e => {
    if (e === "table") {
      dispatch(setWideCard(true))
    } else {
      dispatch(setWideCard(false))
    }
    setView(e);
  };

  useEffect(() => {
    const storedDate = sessionStorage.getItem('calendarDate');
    const initDate = storedDate ? new Date(storedDate) : new Date();
    setDate(initDate);

    const initialView = sessionStorage.getItem('calView') || 'month';
    updateCalRange(initDate, initialView);
  }, []);

/*  useEffect(() => {
    if (calRange.startTime && calRange.endTime && view == 'calendar') {
      fetchActivities(filters, 'calendar', calRange);
    }
  }, [calRange.startTime, calRange.endTime, filters, view]);*/

  const updateCalRange = (currentDate, currentView = 'month') => {
    const momentDate = moment(currentDate);
    const start = momentDate.clone().startOf(currentView).startOf('week').unix();
    const end = momentDate.clone().endOf(currentView).endOf('week').unix();

    setCalRange(prevCalRange => {
      if (prevCalRange.startTime !== start || prevCalRange.endTime !== end) {
        return {startTime: start, endTime: end};
      }
      return prevCalRange;
    });
  };

  useEffect(() => {
    if (auth.userData) {
      setShowTable(auth.userData.tour_guide == "1")
    }
  }, [auth]);

  return (
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.newsList}>
            <div className={classes.spaceBetween}>
              <SmallTitle text={translate('activities-list', 'title_activities_list')}/>
              {(hasPermission(permissions.ACTIVITIES_EDITOR_APPROVAL) ||
                      hasPermission(permissions.ACTIVITIES_SECURITY_APPROVAL)) &&
                  <div className={classes.selectGroup}>
                    <span className={classes.selectLabel}>{translate('', 'label_show')}</span>
                    <div style={{width: 200}}>
                      <Select fullWidth data={statusFilter} selected={filters.status_id}
                              onChange={(e) => {
                                setFilters('status_id', e);
                              }}/>
                    </div>
                  </div>
              }
            </div>
            <div>
              <Text text={translate('activities-list', 'sub_title_activities_list')}/>
            </div>
            <div className={classes.spaceBetween}>
              <div className={classes.categoriesGroup}>
              </div>
              <ViewTypeSelector viewType={view} onChange={handleChangeView} type='activities'
                                showTable={showTable}/>
            </div>
            {view === 'activity' && activities.data.length > 0 &&
              <Fragment>
                <Grid container className={classes.newsWrap}>
                  {activities.data.map((item, index) => (
                    <Grid key={index} item xs={12} sm={view === 'activity' ? 6 : 12}
                          md={view === 'activity' ? 4 : 12} className='item'>
                      <ListItem viewType={view} path='activities'
                                showStatus={Number(filters.status_id) === 1}
                                statusList={statusList} item={item}/>
                    </Grid>
                  ))}
                </Grid>
                <div className={clsx(classes.flexEnd, classes.paginatorLabel)}>
                  {translate('activities-list', 'label_page_counter').replace('##page##', filters.page).replace('##total##', activities.settings.totalPages)}
                </div>
                <div className={
                  filters.page !== 1 && filters.page !== activities.settings.totalPages ? classes.spaceBetween :
                    filters.page === 1 ? classes.flexEnd : classes.flexStart
                }>
                  {filters.page !== 1 &&
                    <Button label={translate('activities-list', 'button_page_back')} fill
                            onClick={(e) => handlePagination('back')}/>
                  }
                  {!!activities.settings.totalPages && filters.page !== activities.settings.totalPages &&
                    <Button label={translate('activities-list', 'button_page_next')} fill
                            onClick={(e) => handlePagination('next')}/>
                  }
                </div>
              </Fragment>
            }
            {view === 'activity' && activities.data.length == 0 &&
                <Typography className={classes.noDataMessage} variant="body1">
                  {translate('activities-list', 'no_data_message')}
                </Typography>
            }
            {view === 'calendar' &&
                <Calendar
                    className={classes.calendarResponsive}
                    onNavigate={date => {
                      sessionStorage.setItem('calendarDate', date.toISOString()); // Save the date
                      setDate(date); // Update local state
                      const viewMode = sessionStorage.getItem('calView') || 'month'; // Ensure view mode is consistent
                      updateCalRange(date, viewMode);
                    }}
                    date={date}
                    localizer={localizer}
                    eventPropGetter={
                      customeEventItem
                    }
                    onSelectEvent={(event) => history.push(`activities/${event.id}`)}
                    views={['month', 'week', 'day']}
                    messages={{
                      today: translate('activities-list', 'calendar_today'),
                      previous: translate('activities-list', 'calendar_previous'),
                      next: translate('activities-list', 'calendar_next'),
                      month: translate('activities-list', 'calendar_month'),
                      week: translate('activities-list', 'calendar_week'),
                      day: translate('activities-list', 'calendar_day')
                    }}
                    events={
                      activities.data.map(item => {
                        return {
                          id: item.jfm_event.id,
                          title: item.event.title,
                          start: moment.unix(item.jfm_event.event_start).toDate(),
                          end: moment.unix(item.jfm_event.event_end).toDate(),

                          color: getColor(item),
                        }
                      })
                    }
                    startAccessor="start"
                    endAccessor="end"
                    style={{height: 500}}
                />
            }

            {view === 'table' && showTable && (auth.userData.tour_guide == "1") &&
                <ActivitiesListTable sort={sort} setSort={setSort} activities={activities}
                                     guides={guides} fetchAllGuides={fetchAllGuides} page={page}
                                     rowsPerPage={rowsPerPage}
                                     totalItems={totalItems} setPage={setPage} setRowsPerPage={setRowsPerPage}
                                     filter={filter}
                                     setFilter={setFilter} view={view} fetchActivities={fetchActivities}
                                     checkBoxes={checkBoxes} setCheckBoxes={setCheckBoxes}
                                     noGuideTours={noGuideTours}
                                     submitGuideChange={submitGuideChange} exportEvents={exportEvents}/>
            }
          </div>
        </Grid>
      </Grid>
  );
}

const ApplyProvider = (props) => {
  return (<Provider>
    <ActivitiesList {...props} />
  </Provider>)
}
export default memo(ApplyProvider);
