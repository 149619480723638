import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';

import {
    setLogin,
    setUserData
} from '../../redux/auth/authActions';
import useAPI from '../../api/apiHandler';

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};
const AuthPasswordHook = (props) => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.states.loading);
    const [setRequest] = useAPI();
    const [data, setData] = useState({
        ssn: '',
        grant_type: 'bankid',
        session_id: null,
    })
    const [initialize, setInitialize] = useState(false)
    const [invalidList, setInvalidList] = useState([]);
    const [retryBA, setRetryBA] = useState(-1);

    const handleSubmit = (e) => {
        try {
            e.preventDefault()
        } catch (err) {
        }
        ;
        if (validator()) {
            /* var formdata = new FormData();
             formdata.append("ssn", data.ssn);
             formdata.append("grant_type", data.grant_type);
             formdata.append("session_id", data.session_id);*/
            setRequest({
                url: 'start_login',
                method: 'POST',
                data: data,
            }).then(res => {
                if (res.status === 400) {
                    setRetryBA(999);
                } else {
                    setData({...data, session_id: res.data.sessionId})
                    setInitialize(true);
                    setRetryBA(0);
                }
            })
                .catch(() => setRetryBA(999))
        }
    }

    const startHostedGrandId = e => {
        try {
            e.preventDefault()
        } catch (err) {
        }
        setRequest({
            url: "start_login_hosted",
            method: "POST"
        })
            .then(res => {
                if (res.data) {
                    window.location = res.data.redirectUrl;
                }
            })
            .catch(() => setRetryBA(999))
    };

    const handleNavigation = () => {
        const {history} = props;
        history.push(`login${window.location.search ? window.location.search : ''}`);
    }
    const handleApplyAccount = () => {
        const {history} = props;
        history.push('application');
    }

    const checkStatus = (params) => {
        let _data = {
            grant_type: "bankid",
            session_id: data.session_id || params.session_id
        };

        setRequest({
            url: 'check_login_status',
            method: 'POST',
            data: _data,
        }).then(async res => {
            if (res) {
                if (res.data && res.data.access_token) {
                    let {access_token, refresh_token, expires_in} = res.data;
                    dispatch(setLogin({
                        access_token: access_token,
                        refresh_token: refresh_token,
                        expires_in: expires_in,
                        role: parseJwt(access_token).role,
                        lastRefresh: moment().unix()
                    }))
                } else {
                    checkForRetry();
                }
            }
        })
    }

    const checkForRetry = () => {
        setTimeout(() => {
            if (retryBA < 200) {
                setRetryBA(oldRBA => oldRBA + 1);
            }
        }, 1250);
    }

    const fetchUserData = () => {
        setRequest({
            url: 'user/profile',
            method: 'GET',
        }).then(res => {
            if (res) {
                dispatch(setUserData(res.data))
            }
        })
    }

    useEffect(() => {
        if (initialize) {
            checkStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retryBA])

    const handleChange = (value, id) => {
        setData({...data, [id]: value})
    }

    const handleCancel = () => {
        setData({...data, session_id: null})
        setInitialize(false);
        setRetryBA(990);
    }

    const validator = () => {
        let invalid = [];
        Object.keys(data).forEach((key) => {
            var value = data[key];
            switch (key) {
                case 'ssn':
                    if (!data.session_id && (!value || value.length === 0)) {
                        invalid.push('ssn')
                    }
                    break;
                default:
                    return true;
            }
        });
        setInvalidList(invalid);
        return invalid.length === 0
    }

    return [data, initialize, handleNavigation, handleApplyAccount, handleSubmit, handleChange, handleCancel, invalidList, loading, checkStatus, startHostedGrandId, setData];
}

export default AuthPasswordHook;