import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Router from './routes/router';
import {store, persistor} from './redux/store';
import {Provider} from 'react-redux';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {PersistGate} from 'redux-persist/integration/react'
import moment from 'moment';
import 'moment/locale/sv'
import 'moment/locale/pt'
import {Helmet} from "react-helmet";
import translate from "./functions/applyTranslation";

moment.locale('sv')

ReactDOM.render(
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                    <Helmet>
                        <meta name="description" content={translate("", "sharing_url_text")}/>
                        <title>JFM</title>
                    </Helmet>
                    <Router/>
            </PersistGate>
        </Provider>
    </MuiPickersUtilsProvider>,
    document.getElementById('root')
);