import {useDispatch, useSelector} from 'react-redux';
import {
    setError
} from '../redux/states/statesActions'
import {setLogin, setLogout} from '../redux/auth/authActions';
import axios from 'axios';
import translate from '../functions/applyTranslation';
import moment from 'moment';

const server = window.location.origin;
const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

const APICall = () => {
    const dispatch = useDispatch();
    const {access_token, expires_in, refresh_token, lastRefresh} = useSelector(state => state.auth);
    let awaiting;

    const refreshToken = async (params) => {
        try {
            const response = await axios({
                url: `${server}/api/refresh_token`,
                method: "POST",
                headers: {'Authorization': `Bearer ${refresh_token}`}
            });

            params.access_token = response.data.access_token;
            await dispatch(setLogin({
                access_token: response.data.access_token,
                refresh_token: response.data.refresh_token,
                expires_in: response.data.expires_in,
                role: parseJwt(response.data.access_token).role,
                lastRefresh: moment().unix()
            }))

            return await apiCall(params, false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(setLogout());
            }
        }
    }

    const apiCall = async (props, firstTime = true) => {

        if (access_token && refresh_token && expires_in && (expires_in < moment().unix())) {
            dispatch(setLogout());
            return
        }

        if (!props) {
            throw {error: "Missing props"};
        }

        const {method, url, data, displayError, responseType} = props;

        const contentType = data instanceof FormData ? 'multipart/form-data' : 'application/json';

        if (firstTime && url && !url.startsWith('notifications') && !url.startsWith('login') && !url.startsWith('check_login_status') && (lastRefresh < moment().add(-120, 'seconds').unix())) {
            const params = {...props, contentType, firstTime}
            const result = await refreshToken(params)
            return result;
        }

        if (url) {
            const config = {
                method: method || "GET",
                url: `${server}/api/${url}`,
                data: data,
                headers: {'Content-Type': contentType}
            };

            if (!firstTime) {
                config.headers['Authorization'] = `Bearer ${props.access_token}`;
            } else {
                if (access_token) {
                    config.headers['Authorization'] = `Bearer ${access_token}`;
                }
            }
            if (responseType) {
                config.responseType = responseType;
            }

            try {
                return await axios(config)
            } catch (error) {
                if (error.response && error.response.status) {
                    let _err = error.response.status > 499
                        ? {error: translate('', 'generic_api_call_error'), err: null}
                        : {
                            error: typeof error.response.data.message === 'string'
                                ? translate('api', error.response.data.message)
                                : translate('', 'generic_api_call_error'),
                            err: error.response
                        };

                    if (displayError) {
                        dispatch(setError(_err));
                    }
                    return false;
                }
            }
        } else {
            throw {error: "Missing URL"}
        }

    };

    const apiMultipleCalls = async (options, atemp = 1) => {
        if (access_token && refresh_token && expires_in && (expires_in < moment().unix())) {
            dispatch(setLogout());
            return;
        }
        if (!options && !options.length) {
            let err = {error: 'Missing Requests'}
            throw err
        } else {
            return Promise.all(options.map(item => apiCall(item, atemp)));
        }
    };


    return [apiCall, apiMultipleCalls];
}

export const useImage = () => {
    const access_token = useSelector(state => state.auth.access_token);
    const getImageURL = (id) => {
        return `${server}/api/file/download?file_id=${id}&token=${access_token}`;
        // return `/api/file/download?file_id=${id}&token=${access_token}`;
    }
    return [getImageURL];

}
export default APICall;
